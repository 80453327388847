import { Typography } from "@material-tailwind/react";
import { Link } from "react-scroll";
import { AiFillLinkedin, AiOutlineTwitter } from 'react-icons/ai'
export default function Footer() {
    return (
        <>
            <div className="max-w-screen-xl mx-auto bg-white rounded-xl p-6">
                <Link to="" className='cursor-pointer text-3xl text-black transition-all duration-500 ease-linear hover:text-main-Green'>
                    Modern <span className='ml-2 logo'>life</span>
                </Link>
                <div className="max-w-screen-lg mx-auto max-lg:flex-wrap  flex mt-12">
                    <div className="w-full sm:w-1/2 max-lg:mt-6 lg:w-1/4">
                        <Typography
                            as='li'
                            variant='small'
                            color='blue-gray'
                            className='p-1 font-normal'
                        >
                            <Link
                                to=''
                                activeClass='active'
                                spy={true}
                                smooth={true}
                                offset={50}
                                duration={500}
                                className='flex items-center text-xl font-medium text-black transition-all duration-300 ease-linear cursor-pointer hover:text-main-Green font-PlayFair'
                            >
                                Advisors
                            </Link>
                        </Typography>
                        <Typography
                            as='li'
                            variant='small'
                            color='blue-gray'
                            className='p-1 font-normal'
                        >
                            <Link
                                to=''
                                activeClass='active'
                                spy={true}
                                smooth={true}
                                offset={50}
                                duration={500}
                                className='flex items-center text-xl font-medium text-black transition-all duration-300 ease-linear cursor-pointer hover:text-main-Green font-PlayFair'
                            >
                                Insurance
                            </Link>
                        </Typography>
                    </div>
                    <div className="w-full sm:w-1/2 max-lg:mt-6 lg:w-1/4">
                        <Typography
                            as='li'
                            variant='small'
                            color='blue-gray'
                            className='p-1 font-normal'
                        >
                            <Link
                                to=''
                                activeClass='active'
                                spy={true}
                                smooth={true}
                                offset={50}
                                duration={500}
                                className='flex items-center text-xl font-medium text-black transition-all duration-300 ease-linear cursor-pointer hover:text-main-Green font-PlayFair'
                            >
                                About Us
                            </Link>
                        </Typography>
                        <Typography
                            as='li'
                            variant='small'
                            color='blue-gray'
                            className='p-1 font-normal'
                        >
                            <Link
                                to=''
                                activeClass='active'
                                spy={true}
                                smooth={true}
                                offset={50}
                                duration={500}
                                className='flex items-center text-xl font-medium text-black transition-all duration-300 ease-linear cursor-pointer hover:text-main-Green font-PlayFair'
                            >
                                Careers
                            </Link>
                        </Typography>
                        <Typography
                            as='li'
                            variant='small'
                            color='blue-gray'
                            className='p-1 font-normal'
                        >
                            <Link
                                to=''
                                activeClass='active'
                                spy={true}
                                smooth={true}
                                offset={50}
                                duration={500}
                                className='flex items-center text-xl font-medium text-black transition-all duration-300 ease-linear cursor-pointer hover:text-main-Green font-PlayFair'
                            >
                                Press Kit
                            </Link>
                        </Typography>
                    </div>
                    <div className="w-full sm:w-1/2 max-lg:mt-6 lg:w-1/4">
                        <Typography
                            as='li'
                            variant='small'
                            color='blue-gray'
                            className='p-1 font-normal'
                        >
                            <Link
                                to=''
                                activeClass='active'
                                spy={true}
                                smooth={true}
                                offset={50}
                                duration={500}
                                className='flex items-center text-xl font-medium text-black transition-all duration-300 ease-linear cursor-pointer hover:text-main-Green font-PlayFair'
                            >
                                Insights
                            </Link>
                        </Typography>
                        <Typography
                            as='li'
                            variant='small'
                            color='blue-gray'
                            className='p-1 font-normal'
                        >
                            <Link
                                to=''
                                activeClass='active'
                                spy={true}
                                smooth={true}
                                offset={50}
                                duration={500}
                                className='flex items-center text-xl font-medium text-black transition-all duration-300 ease-linear cursor-pointer hover:text-main-Green font-PlayFair'
                            >
                                Business Strategies
                            </Link>
                        </Typography>
                        <Typography
                            as='li'
                            variant='small'
                            color='blue-gray'
                            className='p-1 font-normal'
                        >
                            <Link
                                to=''
                                activeClass='active'
                                spy={true}
                                smooth={true}
                                offset={50}
                                duration={500}
                                className='flex items-center text-xl font-medium text-black transition-all duration-300 ease-linear cursor-pointer hover:text-main-Green font-PlayFair'
                            >
                                Estate Planning
                            </Link>
                        </Typography>
                        <Typography
                            as='li'
                            variant='small'
                            color='blue-gray'
                            className='p-1 font-normal'
                        >
                            <Link
                                to=''
                                activeClass='active'
                                spy={true}
                                smooth={true}
                                offset={50}
                                duration={500}
                                className='flex items-center text-xl font-medium text-black transition-all duration-300 ease-linear cursor-pointer hover:text-main-Green font-PlayFair'
                            >
                                Wealth Accumulation
                            </Link>
                        </Typography>
                        <Typography
                            as='li'
                            variant='small'
                            color='blue-gray'
                            className='p-1 font-normal'
                        >
                            <Link
                                to=''
                                activeClass='active'
                                spy={true}
                                smooth={true}
                                offset={50}
                                duration={500}
                                className='flex items-center text-xl font-medium text-black transition-all duration-300 ease-linear cursor-pointer hover:text-main-Green font-PlayFair'
                            >
                                Retirement
                            </Link>
                        </Typography>
                        <Typography
                            as='li'
                            variant='small'
                            color='blue-gray'
                            className='p-1 font-normal'
                        >
                            <Link
                                to=''
                                activeClass='active'
                                spy={true}
                                smooth={true}
                                offset={50}
                                duration={500}
                                className='flex items-center text-xl font-medium text-black transition-all duration-300 ease-linear cursor-pointer hover:text-main-Green font-PlayFair'
                            >
                                Charitable Giving
                            </Link>
                        </Typography>
                    </div>
                    <div className="w-full sm:w-1/2 max-lg:mt-6 lg:w-1/4">
                        <Typography
                            as='li'
                            variant='small'
                            color='blue-gray'
                            className='p-1 font-normal'
                        >
                            <Link
                                to=''
                                activeClass='active'
                                spy={true}
                                smooth={true}
                                offset={50}
                                duration={500}
                                className='flex items-center text-xl font-medium text-black transition-all duration-300 ease-linear cursor-pointer hover:text-main-Green font-PlayFair'
                            >
                                Product Guides
                            </Link>
                        </Typography>
                        <Typography
                            as='li'
                            variant='small'
                            color='blue-gray'
                            className='p-1 font-normal'
                        >
                            <Link
                                to=''
                                activeClass='active'
                                spy={true}
                                smooth={true}
                                offset={50}
                                duration={500}
                                className='flex items-center text-xl font-medium text-black transition-all duration-300 ease-linear cursor-pointer hover:text-main-Green font-PlayFair'
                            >
                                Underwriting
                            </Link>
                        </Typography>
                        <Typography
                            as='li'
                            variant='small'
                            color='blue-gray'
                            className='p-1 font-normal'
                        >
                            <Link
                                to=''
                                activeClass='active'
                                spy={true}
                                smooth={true}
                                offset={50}
                                duration={500}
                                className='flex items-center text-xl font-medium text-black transition-all duration-300 ease-linear cursor-pointer hover:text-main-Green font-PlayFair'
                            >
                                News
                            </Link>
                        </Typography>
                        <Typography
                            as='li'
                            variant='small'
                            color='blue-gray'
                            className='p-1 font-normal'
                        >
                            <Link
                                to=''
                                activeClass='active'
                                spy={true}
                                smooth={true}
                                offset={50}
                                duration={500}
                                className='flex items-center text-xl font-medium text-black transition-all duration-300 ease-linear cursor-pointer hover:text-main-Green font-PlayFair'
                            >
                                Webinars
                            </Link>
                        </Typography>

                    </div>
                </div>
                <div className="border-t-[1px] py-6 text-sm mt-12 text-[#777] flex flex-col space-y-4">
                    <div className="flex justify-between">
                        <div>
                            © 2023 Modern Life
                            Licenses
                            Privacy
                            Terms
                        </div>
                        <div className="flex space-x-2 text-black text-xl">
                            <a href="" className="transition-all duration-300 hover:text-main-Green"><AiFillLinkedin /></a>
                            <a href="" className="transition-all duration-300 hover:text-main-Green"><AiOutlineTwitter /></a>
                        </div>
                    </div>
                    <div>
                        Life insurance technology and advisory services are provided by Modern Life Group, Inc. (“Modern Life”). Modern Life is a licensed insurance producer in all states where it offers products, and acts as an agent for various insurance companies. License information available here. Modern Life does business as Modern Life Insurance Services in CA. The information on this website is for informational purposes only. Products and specific product features may not be available in all states, and other limitations or restrictions may apply.
                    </div>
                    <div>
                        Securities offered through The Leaders Group, Inc. Member <a href="" className="text-main-Green">FINRA/SIPC </a>26 W Dry Creek Circle, Suite 800, Littleton, CO 80120, 303-797-9080. Modern Life is not affiliated with <a href="" className="text-main-Green">The Leaders Group, Inc.</a> Check the background of your financial professional or broker/dealer with FINRA BrokerCheck.
                    </div>
                </div>
            </div>
        </>
    )
}