import { Link } from 'react-scroll'
export default function LinkRepetable (props) {
  return (
    <>
      <Link
        to={props.linkHref}
        className='relative italic font-PlayFair text-main-Green after:absolute after:left-0 after:bottom-0 after:h-[1px] after:w-0 after:bg-main-Green after:transition-all after:duration-300 hover:after:w-full cursor-pointer'
      >
        {props.linkText}
      </Link>
    </>
  )
}
