export default function CardInsurance () {
  const list = [
    {
      textSmall:
        'Modern Life’s innovative solutions accelerate the process of purchasing life insurance, so you can get back to living life.',
      text: '“It’s exciting to work with a company that focuses on solving my problems and offers technology to make the process of buying insurance easier than ever before.”',
      autor: 'Jason Katzenstein',
      job: 'Advisor',
      image:
        'https://assets.website-files.com/617db5b45d5bdc18467fdf99/62f2eb55cbc47fab480b7db2_home-image-1-p-500.webp'
    }
  ]

  return (
    <>
      <div className='max-w-[500px] font-PlayFair max-md:mt-6'>
        {list[0].textSmall}
      </div>
      <div className='flex items-end justify-center max-w-4xl mx-auto space-x-6 max-md:mt-6 max-md:flex-col max-md:space-y-6'>
        <div className='md:w-1/2 bg-[#f8a08c] rounded-xl p-6 font-PlayFair shadow-[2px_4px_24px_rgba(0,0,0,0.1)] sm:text-3xl flex flex-col space-y-12 lg:space-y-16'>
          <div className='p-2'>{list[0].text}</div>
          <div className='text-sm'>
            {list[0].autor} <br /> {list[0].job}
          </div>
        </div>
        <div className='w-full rounded-xl overflow-hidden md:w-1/2  max-md:max-h-[430px]'>
          <img
            src={list[0].image}
            className='object-fill w-full  md:max-h-[555px] rounded-xl'
            alt=''
          />
        </div>
      </div>
    </>
  )
}
