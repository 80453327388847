import CardText from '../repetable/cardText'
import Title from '../repetable/title'
import CardExperiens from './cardExperiens'

export default function HomeExperiens () {
  
    const list = [
        {
            text:'Modern Life and their technology makes writing life insurance easy and helps me advise my clients better. The whole process is seamless and they really go the extra mile to support my life insurance business.”',
            author:'Matthew Lindquist ',
            job:'Insurance Advisor'
        },
        {
            text:'Buying life insurance through my advisor was a high-quality and trusted experience. My advisor was able to explain all components in very understandable terms. I started my search with another entity but found the process cumbersome and very impersonal. Modern Life was a night and day difference.',
            author:'Kenneth Engel',
            job:'Client'
        },
        {
            text:'I can’t speak highly enough of the team and their level of expertise and professionalism. It’s refreshing to see a tech company that also has so much depth on the insurance side.',
            author:'Kevin Daly',
            job:'Advisor'
        },
    ]

    return (
    <>
      <div className='w-full px-6 py-12'>
        <div className='text-center'>
          <Title title='Our experience' titleStyle='runs deep' />
          <div className='mt-12 text-xl font-medium text-black sm:text-2xl font-PlayFair'>
            Here are just some of the things people love about Modern Life.
          </div>
        </div>
        <div className='mt-12'>
          <div className='text-sm text-center'>WHAT PEOPLE ARE SAYING</div>
          <div className='flex lg:space-x-6 max-lg:flex-col max-lg:space-y-6 max-lg:items-center'>
             {
                list && list.map((item, index) => {
                    return <CardText text={item.text} author={item.author} job={item.job} />
                })
             }   
          </div>
        </div>
        <div className='flex justify-center mt-12 space-x-6'>
             <CardExperiens />
        </div>
      </div>
    </>
  )
}
