import React from 'react'

import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button
} from '@material-tailwind/react'
import { Link } from 'react-scroll'
import LinkButton from '../repetable/linkButton'
import LinkRepetable from '../repetable/link'
import Title from '../repetable/title'

export default function HomeFeatures () {
  const list = [
    {
      title: 'Bringing the future',
      titleStyle: 'to life',
      text: 'Modern Life is a tech-enabled life insurance brokerage that knows innovation and insurance in equal measure. This is where sophisticated technology meets brokerage expertise – to make your days faster, more confident, and more streamlined than ever before.',
      image:
        'https://assets.website-files.com/617db5b45d5bdc18467fdf99/61bb776824ea6082162d45e5_advisors__hero-illustration%402x-p-800.webp',
      textSmall:'Leading solutions for leading insurance advisors.',
      linkHref:'',  
      linkText:'Learn more about working with Modern Life',  
    }
  ]

  return (
    <>
      <div className='w-full sm:py-24'>
        <Card className='flex-row max-w-screen-xl mx-auto bg-transparent shadow-none max-md:space-y-8 max-md:flex-col-reverse'>
          <CardBody className='bg-transparent md:w-1/2 xl:px-24'>
            <Title title={list[0].title} titleStyle={list[0].titleStyle} />
            <Typography
              color='black'
              className='mb-8 font-normal font-PlayFair'
            >
              {list[0].text}
            </Typography>
            <LinkButton text="Get Started" href="" />
          </CardBody>
          <CardHeader
            shadow={false}
            floated={false}
            className='flex flex-col items-center justify-center m-0 bg-transparent rounded-none md:w-1/2 shrink-0'
          >
            <img
              src={list[0].image}
              className='rounded-full w-[300px] h-[300px] lg:w-[500px] lg:h-[500px] border-8 border-[#f2e0ca]'
            />
            <div className='block mt-12 text-center max-md:hidden'>
              <Typography color='black' className='font-normal font-PlayFair'>
                {list[0].textSmall}
              </Typography>
              <LinkRepetable linkText={list[0].linkText} linkHref={list[0].linkHref} />  
            </div>
          </CardHeader>
        </Card>
      </div>
    </>
  )
}
