import React from "react";
import Home from "./Pages/home";
import Header from "./Pages/layouts/header";
import Footer from "./Pages/layouts/footer";
function App() {
  return (
<>
    <Header />
    <Home />
</>
  );
}

export default App;
