import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button
} from '@material-tailwind/react'
export default function CardExperiens () {
  const list = [
    {
      title: 'Get started with Modern Life as an advisor today',
      text: 'We combine sophisticated technology with expert support to empower independent life insurance advisors. See the future of insurance brought to life.'
    }
  ]
  return (
    <>
      <Card className='flex-row w-full xl:w-1/3 bg-[#6cd689] rounded-xl overflow-hidden shadow-[2px_4px_24px_rgba(0, 0, 0, 0.4)] max-md:flex-col'>
        <CardBody>
          <Typography variant='h4' color='blue-gray' className='mb-2 text-lg sm:text-xl'>
            {list[0].title}
          </Typography>
          <Typography color='gray' className='mb-8 text-sm font-normal sm:text-md'>
            {list[0].text}
          </Typography>
          <a href='#' className='inline-block'>
            <Button
              variant='text'
              className='text-black flex items-center gap-2 bg-white transition-all duration-300 hover:bg-[rgba(255,255,255,.7)]'
            >
              Get Started
            </Button>
          </a>
        </CardBody>
        <CardHeader
          shadow={false}
          floated={false}
          className='w-1/2 m-0 bg-transparent rounded-r-none max-md:w-full shrink-0'
        >
          <img
            src='https://assets.website-files.com/617db5b45d5bdc18467fdf99/61c30c1bdb237b86849dcf83_005_Man-Suitcase%402x.webp'
            alt='card-image'
            className='object-cover w-full h-full'
          />
        </CardHeader>
      </Card>
    </>
  )
}
