import React, { useState } from "react";
import Footer from "../layouts/footer";
import { TfiArrowTopRight } from 'react-icons/tfi'
import {
  Drawer,
  Button,
  Typography,
  IconButton,
} from "@material-tailwind/react";
export default function HomeLast() {
    const [open, setOpen] = useState(false)
    const [openTop, setOpenTop] = React.useState(false);
  const [openRight, setOpenRight] = React.useState(false);
  const openDrawerTop = () => setOpenTop(true);
  const closeDrawerTop = () => setOpenTop(false);
    return (
        <>
            <div className="w-full  sm:h-[100vh] flex flex-col py-16 justify-end items-center bg-fixed bg-center bg-cover bg-last max-xl:px-6 space-y-6">
                <div className="container mx-auto relative">
                    <Footer />
                </div>

                <div className="container fixed bottom-0">
                    <div className={open ? "max-w-screen-xl bg-[#6cd689] h-[100vh] text-center text-black rounded-t-xl py-2 font-bold cursor-pointer mx-auto transition-all duration-300 relative" : "max-w-screen-xl bg-[#6cd689] h-12 text-center text-black rounded-t-xl py-2 font-bold cursor-pointer mx-auto transition-all duration-300  relative"}>
                    Get started with Modern Life {open ? <TfiArrowTopRight className="absolute right-4 top-4 rotate-[135deg] font-bold text-black" /> : <TfiArrowTopRight className="absolute right-4 top-4 -rotate-45 font-bold text-black" />} 
                    </div>
                </div>
            </div>
        </>
    )
}