import HomeCoverage from "./components/homeCoverage";
import HomeExperiens from "./components/homeExperiens";
import HomeFeatures from "./components/homeFeatures";
import HomeInsurance from "./components/homeInsurance";
import HomeInsuranceSmall from "./components/homeInsuranceSmall";
import HomeLast from "./components/homeLast";
import HomeSolutions from "./components/homeSolutions";

export default function Home() {
    return(
        <>
          <HomeFeatures />  
          <HomeSolutions />
          <HomeInsuranceSmall />
          <HomeInsurance />
          <HomeCoverage />
          <HomeExperiens />
          <HomeLast />
        </>
    )
}