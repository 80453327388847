import {
    Card,
    CardBody,
    Typography,
  } from '@material-tailwind/react'
  
export default function CardText(props) {
    return(
        <>
               <Card className='w-full md:w-1/2 lg:w-1/3 mt-4 md:h-[300px] lg:h-[440px] xl:h-[400px]'>
                    <CardBody className='flex flex-col justify-between h-full max-md:space-y-6'>
                      <Typography
                        variant=''
                        color=''
                        className='mb-2 italic font-medium text-black lg:text-xl xl:text-2xl font-PlayFair'
                      >
                        {props.text}
                      </Typography>
                      <Typography className='text-sm font-medium text-black font-PlayFair'>
                         {props.author} <br /> {props.job}   
                      </Typography>
                    </CardBody>
                  </Card>
        </>
    )
}