import { Typography } from '@material-tailwind/react'
import Title from '../repetable/title'
import CardInsurance from './cardInsurance'
import CardInsuranceLast from './cardInsuranceLast'
import LinkRepetable from '../repetable/link'
export default function HomeInsurance () {
  
  const list = [
     {
        textSmall:'Modern Life’s innovative solutions accelerate the process of purchasing life insurance, so you can get back to living life.',
        text:'“It’s exciting to work with a company that focuses on solving my problems and offers technology to make the process of buying insurance easier than ever before.”',
        autor:'Jason Katzenstein',
        job:'Advisor',
        image:'https://assets.website-files.com/617db5b45d5bdc18467fdf99/62f2eb55cbc47fab480b7db2_home-image-1-p-500.webp'
     } 
    ]

  return (
    <>
      <div className='max-w-screen-xl py-12 mx-auto max-xl:px-6'>
        <Title title='Insurance at the' titleStyle='‍speed of life' />
       <CardInsurance />
       <CardInsuranceLast />
       <div className='block mt-12 text-center'>
              <Typography color='black' className='font-normal font-PlayFair'>
              Still have questions?
              </Typography>
              <LinkRepetable linkText={'Learn more about us'} linkHref="" />  
            </div>
      </div>
    </>
  )
}
