import { Link } from 'react-scroll'
import Title from '../repetable/title'
import {
  Card,
  CardBody,
  Typography,
} from '@material-tailwind/react'

export default function HomeCoverage () {
  const list = [
    {
      title: 'Permanent Life',
      text: 'Life insurance coverage that doesn’t expire and often combines a death benefit with a tax-advantaged savings component that grows based on investment performance. Can be used for a wide range of individual and corporate strategies including estate planning, retirement savings, income protection, and charitable giving.'
    },
    {
      title: 'Term Life',
      text: 'Life insurance protection for a set period of time, usually with fixed terms between 10 to 30 years, without a savings component. Simple and often the most affordable coverage, it is especially effective for needs that are time bound like a mortgage. Often used for income protection and key person insurance.'
    },
    {
      title: 'Long-Term Care',
      text: 'Protection against the expense of needing care to go about activities of daily living for an extended time, due to old age or disability. It is available as a standalone policy or combined with a life insurance policy as a living benefit.'
    },
    {
      title: 'Disability',
      text: 'Long-term disability insurance protection that compensates when unable to work for an extended period of time due to illness or injury. Often based on a fraction of income.'
    }
  ]

  return (
    <>
      <div className='w-full py-12 mt-12 bg-[#eef2e4] max-xl:px-6'>
        <div className='flex max-w-screen-xl mx-auto max-lg:flex-col max-lg:space-y-12'>
          <div className='lg:w-1/2'>
            <Title title='The right coverage to fit' titleStyle='‍every need' />
            <div className='w-full  lg:max-w-[500px] font-PlayFair max-md:mt-6'>
              We partner with leading national carriers to bring the best
              insurance coverage at the greatest value for every client need.
            </div>
            <div className='flex justify-end pr-12 mt-16'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 423 373'
                width='423'
                height='373'
                preserveAspectRatio='xMidYMid meet'
              >
                <defs>
                  <clipPath id='__lottie_element_82'>
                    <rect width='423' height='373' x='0' y='0'></rect>
                  </clipPath>
                </defs>
                <g clip-path='url(#__lottie_element_82)'>
                  <g transform='matrix(1,0,0,1,-24.4580078125,-1)' opacity='1'>
                    <g
                      opacity='1'
                      transform='matrix(1,0,0,1,224.22900390625,216.39599609375)'
                    >
                      <path
                        stroke-linecap='butt'
                        stroke-linejoin='miter'
                        fill-opacity='0'
                        stroke-miterlimit='4'
                        stroke='rgb(0,0,0)'
                        stroke-opacity='1'
                        stroke-width='2'
                        d=' M-166.26699829101562,-214.39599609375 C-208.6320037841797,-144.5229949951172 -222.22900390625,8.715999603271484 -127.88500213623047,34.183998107910156 C-4.548999786376953,67.47899627685547 118.27300262451172,-159.0019989013672 26.591999053955078,-170.427001953125 C-65.08899688720703,-181.8520050048828 -140.56100463867188,214.39599609375 222.22900390625,149.3939971923828'
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <div className='md:w-1/2'>
            {list &&
              list.map((item, index) => {
                return (
                  <Card className='mt-4'>
                    <CardBody>
                      <Typography
                        variant='h5'
                        color=''
                        className='mb-2 text-[#005924] italic font-PlayFair text-2xl font-medium'
                      >
                        {item.title}
                      </Typography>
                      <Typography className='text-sm font-medium text-black font-PlayFair'>
                        {item.text}
                      </Typography>
                    </CardBody>
                  </Card>
                )
              })}
            <div>
              <Link className='bg-[#6cd689] transition-all duration-300 hover:bg-[#99e3ad] cursor-pointer block !w-full py-4 rounded-xl text-center mt-4 font-bold'   to=''>
                Learn more about our insurance products
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
