export default function CardInsuranceLast () {
  const list = [
    {
      image:
        'https://assets.website-files.com/617db5b45d5bdc18467fdf99/62ee87e79f416290df5602ed_home-image-2-p-800.webp',
      text: '“My advisor was professional, knowledgeable, and guided me through the whole process.”',
      author: 'Sean McKee',
      job: 'Client',
      card: [
        {
          text: 'Faster time to policy approval',
          number: '+41%'
        },
        {
          text: 'Average cost savings',
          number: '+17%'
        }
      ]
    }
  ]

  return (
    <>
      <div className='flex max-w-6xl py-6 mx-auto max-md:space-y-6 md:space-x-6 max-md:flex-col'>
        <div className='flex max-md:flex-col md:w-2/3 h-fit max-md:space-y-6 bg-[#f9ffd8] p-6 rounded-xl'>
          <div className='md:w-1/2'>
            <img src={list[0].image} className='rounded-xl' alt='' />
          </div>
          <div className='flex flex-col justify-between text-2xl md:pl-6 md:w-1/2 lg:text-3xl'>
            <div className='font-PlayFair'>
              " My advisor was professional,  knowledgeable, 
              and guided me  through the whole  process."
            </div>
            <div className='text-sm font-PlayFair'>
              {list[0].author} <br /> {list[0].job}
            </div>
          </div>
        </div>
        <div className='md:w-1/3 bg-[#005924] p-6 rounded-xl'>
          {list[0].card &&
            list[0].card.map((item, index) => {
              {
                if (index % 2 == 0) {
                  return <div className="border-b-[1px] py-6 border-white text-white">
                      <div className="text-sm font-PlayFair">{item.text}</div>
                      <div className="mt-4 text-6xl lg:text-8xl">{item.number}</div>
                  </div>
                }else{
                  return <div className="py-6 text-white ">
                      <div className="text-sm font-PlayFair">{item.text}</div>
                      <div className="mt-4 text-6xl lg:text-8xl">{item.number}</div>
                  </div>
                }
              }
            })}
        </div>
      </div>
    </>
  )
}
