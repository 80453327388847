import React from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button
} from '@material-tailwind/react'
import LinkButton from '../repetable/linkButton'
import Title from '../repetable/title'

export default function HomeInsuranceSmall () {
  const list = [
    {
      title: 'Life is beautiful.',
      titleStyle: '‍Now, insurance can be too.',
      text: 'We’re bringing sophisticated, intuitive, integrated technology to streamline the purchase of life insurance. Our thoughtfully designed and streamlined tools simplify every touchpoint – for easy, simple, and beautiful digital experiences.',
      image:
        'https://assets.website-files.com/617db5b45d5bdc18467fdf99/61c3511194e0582862b48ae4_home__life-is-beautiful%402x-p-1080.webp',
      textSmall: 'Leading solutions for leading insurance advisors.',
      linkHref: '',
      linkText: 'Learn more about working with Modern Life'
    }
  ]

  return (
    <>
      <Card className='flex-row-reverse max-w-screen-xl mx-auto bg-[#f9ffd8]   max-md:flex-col my-12'>
        <CardBody className='flex flex-col justify-center bg-transparent md:w-1/2 xl:px-16'>
          <Title title={list[0].title} titleStyle={list[0].titleStyle} />
          <Typography color='black' className='mb-8 font-normal font-PlayFair'>
            {list[0].text}
          </Typography>
          <LinkButton text='Get More' href='' />
        </CardBody>
        <CardHeader
          shadow={false}
          floated={false}
          className='flex flex-col items-center justify-center m-0 bg-transparent rounded-none md:w-1/2 shrink-0'
        >
          <img
            src={list[0].image}
            className=''
          />
        </CardHeader>
      </Card>
    </>
  )
}
