import React, { useState, useEffect } from 'react'
import Logo from '../../assets/img/Logo.png'
import {
  Navbar,
  MobileNav,
  Typography,
  IconButton,
  Collapse
} from '@material-tailwind/react'
import { Link } from 'react-scroll'
export default function Header () {
  const [openNav, setOpenNav] = useState(false)

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => window.innerWidth >= 960 && setOpenNav(false)
    )
  }, [])

  const navList = (
    <ul className='flex flex-col gap-2 mt-2 mb-4 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6'>
      <Typography
        as='li'
        variant='small'
        color='blue-gray'
        className='p-1 font-normal'
      >
        <Link
          to=''
          activeClass='active'
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
          className='flex items-center text-xl font-medium text-black transition-all duration-300 ease-linear cursor-pointer hover:text-main-Green font-PlayFair'
        >
          Advisors
        </Link>
      </Typography>
      <Typography
        as='li'
        variant='small'
        color='blue-gray'
        className='p-1 font-normal'
      >
        <Link
          to=''
          activeClass='active'
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
          className='flex items-center text-xl font-medium text-black transition-all duration-300 ease-linear cursor-pointer hover:text-main-Green font-PlayFair'
        >
          Insurance
        </Link>
      </Typography>
      <Typography
        as='li'
        variant='small'
        color='blue-gray'
        className='p-1 font-normal'
      >
        <Link
          to=''
          activeClass='active'
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
          className='flex items-center text-xl font-medium text-black transition-all duration-300 ease-linear cursor-pointer font-PlayFair hover:text-main-Green'
        >
          About Us
        </Link>
      </Typography>
      <Typography
        as='li'
        variant='small'
        color='blue-gray'
        className='p-1 font-normal'
      >
        <Link
          to=''
          activeClass='active'
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
          className='flex items-center text-xl font-medium text-black transition-all duration-300 ease-linear cursor-pointer font-PlayFair hover:text-main-Green'
        >
          Insights
        </Link>
      </Typography>
    </ul>
  )

  return (
    <>
      <Navbar className='max-w-screen-xl px-4 py-2 mx-auto border lg:px-8 lg:py-4' id='Navbar'>
        <div className='flex items-center justify-between w-full text-blue-gray-900'>
          <Typography
            as=''
            href='#'
            className='mr-4 cursor-pointer py-1.5 font-medium'
          >
            <Link to="" className='text-3xl text-black transition-all duration-500 ease-linear hover:text-main-Green'>
              Modern <span className='ml-2 logo'>life</span>
            </Link>
          </Typography>
          <div className='hidden lg:block'>{navList}</div>
          <IconButton
            variant='text'
            className='w-6 h-6 ml-auto text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden'
            ripple={false}
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                className='w-6 h-6'
                viewBox='0 0 24 24'
                stroke='black'
                strokeWidth={2}
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M6 18L18 6M6 6l12 12'
                />
              </svg>
            ) : (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='w-6 h-6'
                fill='none'
                stroke='black'
                strokeWidth={2}
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M4 6h16M4 12h16M4 18h16'
                />
              </svg>
            )}
          </IconButton>
        </div>
        <Collapse open={openNav}>
          <div className='container mx-auto'>{navList}</div>
        </Collapse>
      </Navbar>
    </>
  )
}
