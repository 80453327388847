import { Typography } from "@material-tailwind/react";

export default function Title (props) {
  return (
    <>
      <Typography
        variant='h1'
        color='black'
        className='mb-2 font-medium font-PlayFair max-lg:text-3xl'
      >
        {props.title} <br />{' '}
        <span className='logo'>{props.titleStyle}</span>
      </Typography>
    </>
  )
}
