import LinkRepetable from '../repetable/link'
import Title from '../repetable/title'
import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button
} from '@material-tailwind/react'
export default function HomeSolutions () {
  const list = [
    {
      titleStyle: 'Elevated  technology',
      title: 'that’s also down-to-earth',
      text: 'Integrated. Fast. Transparent. Super intuitive. Let our thoughtfully designed, end-to-end technology make the complex feel delightfully simple.'
    },
    {
      titleStyle: 'Expert advice',
      title: 'whenever you need it',
      text: 'Our team of insurance experts brings together decades of expertise from the top carriers and brokerages. When you need quality advice, just say the word.'
    },
    {
      titleStyle: 'Leading carriers',
      title: 'to meet every client need',
      text: 'We partner with prominent national carriers to offer comprehensive and competitive products that are tailored to fit each unique client need.'
    }
  ]

  return (
    <>
      <div className='w-full py-12 bg-[#eef2e4] max-xl:px-6'>
        <div className='max-w-screen-xl mx-auto'>
          <Title title={'Modern solutions'} titleStyle={'for modern lives'} />
          <div className='flex mt-12 lg:space-x-8 max-lg:space-y-8 max-lg:flex-col'>
            <div className='flex flex-col xl:items-center lg:w-1/4 justify-evenly'>
              <div>
                <img
                  src='https://assets.website-files.com/617db5b45d5bdc18467fdf99/62ee86ad629f1872424dab04_man-suit-portfolio.svg'
                  alt=''
                />
              </div>
              <div>
                <LinkRepetable linkHref='' linkText='Learn more about us' />
              </div>
            </div>
            {list &&
              list.map((item, index) => {
                return (
                  <Card className='lg:w-1/4'>
                    <CardBody>
                      <Typography
                        variant='h5'
                        color='blue-gray'
                        className='md:w-1/2'
                      >
                        <span className='text-2xl sm:text-4xl logo'>{item.titleStyle}</span> 
                      </Typography>
                      <Typography
                        variant=''
                        color='blue-gray'
                        className=' sm:max-w-[66%] mt-2'
                      >
                        <span className='text-xl font-medium sm:text-2xl'>{item.title}</span> 
                      </Typography>
                      <Typography
                        variant='li'
                        color='blue-gray'
                        className='mt-6 text-sm md:text-[13px] text-black font-normal md:font-medium'
                      >
                        {item.text} 
                      </Typography>
                    </CardBody>
                  </Card>
                )
              })}
          </div>
        </div>
      </div>
    </>
  )
}
